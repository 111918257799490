import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { Observable, first } from 'rxjs';

import { environment } from '@environments/environment';
import { IDeparture, IAddDepartureResponse, IEditDeparture } from '@core/interfaces/departure.interface';
import { IEquipment } from '@core/interfaces/equipment.interface';
import { AuthSsoService } from '@core/services/auth/auth-sso.service';

@Injectable({
  providedIn: 'root',
})
export class DepartureSchedulingApi {
  equipmentList = signal<IEquipment[]>([]);
  hasEquipmentWithDepartureScheduling = computed<boolean>(() => {
    return this.equipmentList().filter((equip: any) => equip.hasDepartureScheduling).length > 0;
  });

  _httpClientWithoutInterceptor;

  constructor(
    private authService: AuthSsoService,
    httpBackend: HttpBackend,
  ) {
    this._httpClientWithoutInterceptor = new HttpClient(httpBackend);

    this.setEquipmentList();
  }

  getHeaders(): HttpHeaders {
    return this.authService.getHeaders();
  }

  getDepartureById(departureId: string) {
    const url = environment.departureScheduling + `v1/departure/${departureId}`;
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.get<IDeparture>(url, { headers: headers });
  }

  setEquipmentList() {
    this.getEquipments()
      .pipe(first())
      .subscribe((res) => this.equipmentList.set(res));
  }

  getEquipments(): Observable<IEquipment[]> {
    const url = environment.departureScheduling + 'v1/equipment';
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.get<IEquipment[]>(url, { headers: headers });
  }

  //TODO REMOVE BELOW
  // getEquipmentWithDepartureEnabled(): Observable<IEquipment[]> {
  //   if (this.equipmentList().length > 0) {
  //     return of([])
  //   } else {
  //     const url = environment.departureScheduling + 'v1/equipment';
  //     const headers = this.getHeaders();
  //     return this._httpClientWithoutInterceptor.get<IEquipment[]>(url, { headers: headers }).pipe(
  //       take(1),
  //       shareReplay(1),
  //       tap((res) => this.equipmentList.set(res)),
  //     );
  //   }

  //   // TODO IMPLEMENT LOGIC BELOW ACCORDING TO CHANGES IN AUTH SERVICE

  //   // if (this.equipmentCache) {
  //   //   return this.equipmentCache;
  //   // } else {
  //   //   if(this.authService.isLoggedIn())
  //   //   return (this.equipmentCache = this.authService.isLoggedIn$.pipe(
  //   //     filter((token) => !!token),
  //   //     take(1),
  //   //     switchMap(() => {
  //   //       const headers = this.getHeaders();
  //   //       return this._httpClientWithoutInterceptor.get<IEquipment[]>(url, { headers: headers });
  //   //     }),
  //   //     shareReplay(1),
  //   //   ));
  //   // }
  // }

  // getDepartureStatus(equipmentRef: string) {
  //   const headers = this.getHeaders();
  //   const url = `${environment.driverAppFacade}/v1/equipment/${equipmentRef}/status`;
  //   return this._httpClientWithoutInterceptor.get<any>(url, { headers: headers });
  // }

  getDeparturesQuery = (startTime: any, endTime: any) =>
    startTime && endTime
      ? `?startDatetime=${startTime}&endDatetime=${endTime}`
      : startTime
        ? `?startDatetime=${startTime}`
        : endTime
          ? `?endDatetime=${endTime}`
          : '';

  getDeparturesByEquipmentRef(
    equipmentReference: string,
    startTime: string = '',
    endTime: string = '',
  ): Observable<IDeparture[]> {
    const query = this.getDeparturesQuery(startTime, endTime);
    const url = `${environment.departureScheduling}v1/equipment/${equipmentReference}/departures${query}`;

    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.get<IDeparture[]>(url, { headers: headers });
  }

  // v1 support for c300 vehicles
  getDeparturesForEquipmentList(
    equipmentReferences: string[],
    startTime: string = '',
    endTime: string = '',
  ): Observable<IDeparture[]> {
    const query = this.getDeparturesQuery(startTime, endTime);
    const url = `${environment.departureScheduling}v1/equipment/departures/getlist${query}`;

    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.post<IDeparture[]>(url, equipmentReferences, { headers: headers });
  }

  // v2 support for c400 and c300 vehicles
  getDeparturesByEquipmentRefListV2(
    externalEquipmentReferences: string[],
    startDate: string = '',
    endDate: string = '',
  ): Observable<IDeparture[]> {
    const query = this.getDeparturesQuery(startDate, endDate);
    const url = `${environment.departureScheduling}v2/equipment/departures/getlist${query}`;

    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.post<IDeparture[]>(url, externalEquipmentReferences, {
      headers: headers,
    });
  }

  addDepartureV1(equipmentReference: string, departureData: IDeparture): Observable<IAddDepartureResponse> {
    const url = `${environment.departureScheduling}v1/equipment/${equipmentReference}/departures`;
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.post<IAddDepartureResponse>(url, departureData, { headers: headers });
  }

  addDepartureV2(equipmentReference: string, departureData: IDeparture): Observable<IAddDepartureResponse> {
    const url = `${environment.departureScheduling}v2/equipment/${equipmentReference}/departures`;
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.post<IAddDepartureResponse>(url, departureData, { headers: headers });
  }

  updateDeparture(departureReference: string, departureData: IEditDeparture) {
    const url = `${environment.departureScheduling}v1/departure/${departureReference}`;
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.put(url, departureData, { headers: headers });
  }

  cancelDeparture(departureReference: string) {
    const url = `${environment.departureScheduling}v1/departure/${departureReference}/cancel`;
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.put(url, {}, { headers: headers });
  }

  cancelDepartureV2(externalEquipmentReference: string, departureId: string) {
    const url = `${environment.departureScheduling}v2/departure/${externalEquipmentReference}/${departureId}/cancel`;
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.put(url, {}, { headers: headers });
  }
}
